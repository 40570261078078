import { Box, Button, Flex, HStack, VisuallyHidden } from '@chakra-ui/react'
import * as React from 'react'
import { Link } from 'gatsby'
import { Logo } from '../../assets/svg/Logo'
import { MobileNav } from './MobileNav'
import { NavLink } from './NavLink'

export const NavBar = () => {
  return (
    <Box as="header" bg="transparent">
      <Box
        maxW="7xl"
        mx="auto"
        py="6"
        px={{
          base: '6',
          md: '8',
        }}
      >
        <Flex as="nav" justify="space-between">
          <HStack spacing="8">
            <Box as="a" href="/" rel="home">
              <VisuallyHidden>Return to home page</VisuallyHidden>
              <Logo iconColor="white" h="9" />
            </Box>
          </HStack>
          <Flex align="center">
            <HStack
              spacing="16"
              display={{
                base: 'none',
                md: 'flex',
              }}
            >
              <NavLink.Desktop to="/faq">FAQ</NavLink.Desktop>
              <NavLink.Desktop to="/how-it-works">How It Works</NavLink.Desktop>
              <Link to="/contact">
                <Button variant="white-translucent" size="base">
                  Get Started
                </Button>
              </Link>
            </HStack>
            <Box ml="5">
              <MobileNav />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}
