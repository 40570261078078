import { Box, chakra } from '@chakra-ui/react'
import * as React from 'react'

const DesktopNavLink = (props) => {
  const { active, to, ...rest } = props
  return (
    <chakra.a
      href={to}
      aria-current={active ? 'page' : undefined}
      transition="all .25s ease-in-out"
      fontWeight="semibold"
      color="white"
      {...rest}
      _activeLink={{
        fontWeight: 'bold',
      }}
      _hover={{
        textShadow: '1px 0 10px #fff',
      }}
    />
  )
}

const MobileNavLink = (props) => {
  const { children, href } = props
  return (
    <Box
      as="a"
      href={href}
      p="3"
      rounded="md"
      cursor="pointer"
      fontWeight="medium"
      color="brand.black"
      _hover={{
        bg: 'gray.100',
      }}
    >
      {children}
    </Box>
  )
}

export const NavLink = {
  Desktop: DesktopNavLink,
  Mobile: MobileNavLink,
}
