import { Box, Stack } from '@chakra-ui/react'
import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Copyright } from './Copyright'
import { Logo } from '../../assets/svg/Logo'

export const Footer = () => (
  <Box
    as="footer"
    role="contentinfo"
    mx="auto"
    maxW="7xl"
    py="8"
    px={{
      base: '4',
      md: '8',
    }}
  >
    <Stack direction="column" spacing="4" align="center">
      <GatsbyLink to="/">
        <Logo h="8" />
      </GatsbyLink>
      <Copyright />
    </Stack>
  </Box>
)
