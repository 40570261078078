import { extendTheme } from "@chakra-ui/react"

const colors = {
  brand: {
    primary: '#970725',
    black: '#171821',
  },
  utility: {
    'alpha10': 'rgba(255, 255, 255, 0.1)',
    'alpha25': 'rgba(255, 255, 255, 0.25)',
    'alpha50': 'rgba(255, 255, 255, 0.5)'
  }
};

const fonts = {
  body: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",sans-serif',
  heading: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",sans-serif',
};

const styles = {
  global: {
    'body': {
      color: 'white',
      backgroundColor: 'brand.black',
    },
    '::selection': {
      color: 'white',
      backgroundColor: 'brand.primary',
    },
    '.grecaptcha-badge': {
      visibility: 'hidden',
    }
  }
};

const Button = {
  baseStyle: {
    bg: 'brand.primary',
    color: 'white',
    rounded: 'base',
  },
  sizes: {
    base: {
      h: 12,
      px: 6,
      fontSize: 'md'
    },
    lg: {
      h: 16,
      px: 8,
      fontSize: 'lg'
    }
  },
  variants: {
    'white-translucent': {
      bg: 'utility.alpha25',
      border: '2px',
      borderColor: 'white',
    }
  }
};

const components = {
  Button
};

const theme = extendTheme({
  colors,
  fonts,
  styles,
  components
});

export default theme;
