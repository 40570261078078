import {
  Box,
  Button,
  Center,
  Flex,
  useBoolean,
  useFocusOnShow,
  VStack,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import FocusLock from 'react-focus-lock'
import { HiOutlineX, HiOutlineMenu } from 'react-icons/hi'
import { RemoveScroll } from 'react-remove-scroll'
import { Logo } from '../../assets/svg/Logo'
import { NavLink } from './NavLink'
import { Backdrop } from '../backdrop'

const variants = {
  show: {
    display: 'revert',
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
  hide: {
    opacity: 0,
    scale: 0.98,
    transition: {
      duration: 0.1,
      ease: 'easeIn',
    },
    transitionEnd: {
      display: 'none',
    },
  },
}

const Transition = (props) => {
  const { in: inProp, ...rest } = props
  return (
    <motion.div
      {...rest}
      initial={false}
      variants={variants}
      animate={inProp ? 'show' : 'hide'}
      style={{
        transformOrigin: 'top right',
        position: 'absolute',
        width: 'calc(100% - 32px)',
        top: '24px',
        left: '16px',
        margin: '0 auto',
        zIndex: 41,
      }}
    />
  )
}

export const MobileNav = () => {
  const [show, { toggle, off }] = useBoolean()
  const ref = React.useRef(null)
  useFocusOnShow(ref, {
    visible: show,
    shouldFocus: true,
  })
  return (
    <>
      <Box
        as="button"
        type="button"
        p="1"
        fontSize="2xl"
        color="white"
        onClick={toggle}
        display={{
          base: 'block',
          lg: 'none',
        }}
        _hover={{
          cursor: 'pointer'
        }}
      >
        <HiOutlineMenu />
      </Box>

      <Transition in={show}>
        <RemoveScroll enabled={show}>
          <Backdrop show={show} onClick={off} />
        </RemoveScroll>
        <FocusLock disabled={!show} returnFocus>
          <Box
            bg={mode('white', 'gray.700')}
            shadow="lg"
            rounded="lg"
            ref={ref}
            tabIndex={0}
            outline={0}
          >
            <Box pt="5" pb="6" px="5">
              <Flex justify="space-between" align="center">
                <GatsbyLink to="/">
                  <Logo iconColor="brand.primary" h="8" />
                </GatsbyLink>
                <Box mr="-2" mt="-2">
                  <Center
                    as="button"
                    type="button"
                    onClick={off}
                    rounded="base"
                    p="1"
                    color="gray.600"
                    _hover={{
                      cursor: 'pointer',
                    }}
                  >
                    <Box srOnly>Close menu</Box>
                    <HiOutlineX aria-hidden fontSize="1.5rem" />
                  </Center>
                </Box>
              </Flex>
              <VStack
                as="nav"
                spacing="4"
                mt="8"
              >
                <NavLink.Mobile href="/faq/">FAQ</NavLink.Mobile>
                <NavLink.Mobile href="/how-it-works/">How It Works</NavLink.Mobile>
              </VStack>
              <VStack mt="8" spacing="4">
                <Button as="a" href="/contact/" w="full" variant="base" size="base">
                  Get Started
                </Button>
              </VStack>
            </Box>
          </Box>
        </FocusLock>
      </Transition>
    </>
  )
}
