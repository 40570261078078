import * as React from 'react'
import { Portal } from '@chakra-ui/react'
import { motion } from 'framer-motion'

export const Backdrop = ({ show, onClick }) => (
  <Portal>
    <motion.div
      onClick={onClick}
      initial={false}
      animate={show ? 'show' : 'hide'}
      transition={{
        duration: 0.1,
      }}
      variants={{
        show: {
          opacity: 1,
          display: 'revert',
        },
        hide: {
          opacity: 0,
          transitionEnd: {
            display: 'none',
          },
        },
      }}
      style={{
        width: '100%',
        height: '100vh',
        position: 'absolute',
        background: 'rgba(0,0,0,0.65)',
        inset: 0,
      }}
    />
  </Portal>
)
