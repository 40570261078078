import * as React from 'react'
import { Box, Button, Heading, Text, Img } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import fractalBg from '../../assets/svg/info-illustrations/jagged-bg.png'

export const CtaSimple = ({
  title,
  cta,
  helperText,
  graphic,
  illustration,
  titleSize,
  linkTo,
  fullHeight,
  pageHeader,
  showBg,
  maxW
}) => (
  <Box
    as="section"
    bgImage={showBg ? fractalBg : null}
    bgPosition="center"
    bgRepeat="no-repeat"
    bgSize="cover"
    h={{
      base: fullHeight ? 'calc(100vh - 80px)' : 'auto',
      md: fullHeight ? 'calc(100vh - 96px)' : 'auto',
    }}
  >
    <Box
      mx="auto"
      px={{
        base: '6',
        lg: '8',
      }}
      py={{
        base: '16',
        sm: '40',
      }}
      textAlign="center"
      display="flex"
      flexDir="column"
      justifyContent="flex-start"
      alignItems="center"
      maxW={maxW ? maxW : pageHeader ? '820px' : 'auto'}
    >
      {graphic && <Box mb={12}>{graphic}</Box>}
      {title && (
        <Heading
          as="h2"
          size={titleSize ? titleSize : '2xl'}
          fontWeight={pageHeader ? '300' : '700'}
          lineHeight="shorter"
          mb={!helperText && !cta ? 0 : 6}
          whiteSpace="pre-line"
        >
          {title}
        </Heading>
      )}
      {helperText && (
        <Text w="100%" maxW={pageHeader ? '100%' : '640px'} fontSize="xl">
          {helperText}
        </Text>
      )}
      {illustration && (
        <Img
          pos="relative"
          w="100%"
          maxW={300}
          height="auto"
          src={illustration}
          alt="Illustration of a QA engineer testing a mobile application"
        />
      )}
      {cta && (
        <Button
          mt="8"
          as={GatsbyLink}
          to={linkTo}
          size="lg"
          variant="white-translucent"
          width="100%"
          maxW="300px"
          px={{
            base: '6',
            lg: '32',
          }}
        >
          {cta}
        </Button>
      )}
    </Box>
  </Box>
)
