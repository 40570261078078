import { Box, Text } from '@chakra-ui/layout'
import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'

export const Copyright = (props) => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
    <Text fontSize="md" {...props}>
      &copy; {new Date().getFullYear()} <strong>Synapse Studios</strong>
    </Text>
    <Text fontSize="sm" textAlign="center">
      <GatsbyLink to="/privacy">Privacy Policy</GatsbyLink> | <GatsbyLink to="/terms">Terms of Use</GatsbyLink>
    </Text>
    <Text mt="3" fontSize="xs" textAlign="center">
      This site is protected by reCAPTCHA and the Google <u><a href="https://policies.google.com/privacy" title="Google Privacy Policy" target="_blank" rel="noreferrer">Privacy Policy</a></u> and <u><a href="https://policies.google.com/terms" title="Google Terms of Service" target="_blank" rel="noreferrer">Terms of Service</a></u> apply.
    </Text>
  </Box>
)
