import * as React from "react"
import PropTypes from "prop-types"
import { ChakraProvider } from '@chakra-ui/react'
import theme from '../../theme';
import { Footer } from '../footer'

const Layout = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <main>{children}</main>
      <Footer />
    </ChakraProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
